<template>
  <div class="my_info">
    <div class="header">
      <div @click="handelGoLogin()">
        <img class="header_img" src="@/assets/images/default_img.png" alt="" />
        <h5 v-if="!loginUser.name">
          未登录 <img src="@/assets/images/go.png" alt="" />
        </h5>
        <h5 v-if="loginUser.name">{{ loginUser.name }}</h5>
        <h6 v-if="loginUser.phoneNum">{{ loginUser.phoneNum }}</h6>
      </div>
    </div>
    <div class="menu" v-if="loginUser.name">
      <div class="item" @click="handelMyEvaluation()">
        <img class="icon" src="@/assets/images/book.png" alt="" />
        <span>我的测评</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
      <div class="item" @click="handelUserInfo()">
        <img class="icon" src="@/assets/images/people.png" alt="" />
        <span>个人信息</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
       <div class="item" @click="handelComprehensive()" v-if="loginUser.role == 9">
        <img class="icon" src="@/assets/images/cpzh.png" alt="" />
        <span>综合测评报告</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
    </div>
    <div class="menu" v-if="loginUser.name" style="margin-top: 0.2rem;">
      <div class="item" @click="handelLoginOut()">
        <img class="icon" src="@/assets/images/out.png" alt="" />
        <span>退出登录</span>
        <img class="right_icon" src="@/assets/images/right_icon.png" alt="" />
      </div>
    </div>
    <!-- 错误信息弹框 -->
    <van-popup v-model="show" :close-on-click-overlay="clickFalg" :round="round">
      <div class="error_box">
        <img class="close_pop" @click="show = false" src="@/assets/images/close_pop.png" alt="">
        <div class="content">
          <img src="@/assets/images/error.png" alt="">
          <span>{{message}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import { getLogOut, getComprehensive } from "@/request/api";
export default {
  data() {
    return {
      loginUser: {},
      show: false,
      clickFalg: false,
      round: true,
      message: ''
    };
  },
  components: {},
  methods: {
    // 去登录
    handelGoLogin() {
      if (!this.loginUser.name) {
        this.$router.push("/Login");
      } 
      // else {
      //   this.$router.push({ path: "/report", query: { code: 13110000 } });
      // }
    },

    // 我的测评
    handelMyEvaluation() {
      this.$router.push("/myAssessment");
    },

    // 个人信息
    handelUserInfo() {
      this.$router.push("/userInfo");
    },

    // 退出登录
    handelLoginOut() {
      getLogOut().then((res) => {
        console.log(res);
        localStorage.removeItem("authorization");
        localStorage.removeItem("loginUser");
        localStorage.removeItem("retUrl");
        this.$router.push("/Login");
      });
    },

    // 综合测评报告
    handelComprehensive() {
      let param = {
        studentId: this.loginUser.id
      };
      getComprehensive(param).then((res) => {
        console.log(res)
        if(res.data.errorCode) {
          this.show = true;
          this.message = res.data.message
        } else {
          localStorage.setItem("comprehensiveReport", JSON.stringify(res));
          this.$router.push("/comprehensiveReport");
        }
      })
    },
  },
  created() {
    this.loginUser = localStorage.getItem("loginUser") ? JSON.parse(localStorage.getItem("loginUser")) : {};
    console.log(this.loginUser)
  },
};
</script>

<style scoped lang="less">
.my_info {
  width: 100%;
  min-height: calc(100vh - 0.88rem - 0.98rem);
  background: #f3f3f3;

  .header {
    width: 100%;
    height: 3.7rem;
    background-image: url("../../assets/images/my_bg.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .header_img {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.26rem;
      text-align: center;
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;

      h5 {
        font-size: 0.32rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;

        img {
          width: 0.3rem;
          height: 0.3rem;
          margin-left: 0.1rem;
        }
      }

      h6 {
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .menu {
    padding: 0 0.28rem;
    background-color: #ffffff;

    .item {
      display: flex;
      align-items: center;
      height: 1rem;
      font-size: 0.3rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #333333;
      position: relative;
      border-bottom: 0.01rem solid #eeeeee;

      .icon {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.24rem;
      }

      .right_icon {
        width: 0.14rem;
        height: 0.29rem;
        position: absolute;
        top: 50%;
        right: 0.24rem;
        transform: translateY(-50%);
      }
    }

    .item:last-child {
      border-bottom: none;
    }
  }

  .menu:last-child {
    margin-top: 0.2rem;
  }

  .error_box {
    width: 6.5rem;
    height: 2.0rem,;
    background: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .close_pop {
      width: 0.24rem;
      height: 0.24rem;
      display: block;
      position: absolute;
      right: 0.3rem;
      top: 0.3rem;
    }

    .content {
      display: flex;
      align-items: center;

      img {
        width: 0.37rem;
        height: 0.37rem;
        display: block;
        margin-right: 0.2rem;
      }

      span {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  /deep/ .van-popup--center.van-popup--round {
    border-radius: 0.1rem;
  }
}
</style>
