<template>
  <div class="my">
    <navigationBar :showBack="showBack">我的</navigationBar>
    <myInfo></myInfo>

    <tabBar :active="1"></tabBar>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import myInfo from "@/components/myInfo/myInfo";
import tabBar from "@/components/tabBar/tabBar";
export default {
  data() {
    return {
      showBack: false
    };
  },
  components: {
    navigationBar,
    myInfo,
    tabBar,
  },
  created() {
    
  },
  mounted() {
    
  }
};
</script>

<style scoped lang="css">
.my {
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
}
</style>
